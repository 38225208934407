.grid-container2 {
  align-items: center;
  justify-content: center;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-left: auto; /* Set left margin to auto */
  margin-right: auto; /* Set right margin to auto */
  max-width: 1000px;
  width: 100%;
}

.grid-item2 {
  /* height: 300px; */
  text-align: center;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 1em;
  box-sizing: border-box;
  margin: 15px;
  padding: 10px 20px;
  font-size: 18px;
  width: 400px;
  font-weight: 400;
  color: black;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}

.grid-item2 img {
  width: 100%;
  height: 200px;
}

#portfolio h2 {
  text-align: center;
  font-size: 48px;
  font-family: "Courier New", monospace;
  color: black;
}

.grid-item2 a {
  color: black;
  text-decoration: none;
  text-align: center;
}

.grid-item2 a:hover {
  text-decoration: underline;
}

.grid-item2:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
