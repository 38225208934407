.fade-in-right {
  opacity: 0;
  transform: translateX(100px); /* Initial position outside the viewport */

  /* Other styling */
}

.fade-in-right.visible {
  animation: fadeInRight 0.5s ease forwards;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(100px); /* Start position outside the viewport */
  }
  to {
    opacity: 1;
    transform: translateX(0); /* End position at the starting point */
  }
}
