.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #f0e2c5;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main {
  margin-top: 150px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
  padding-bottom: 65px;
}

#page-container {
  position: relative;
  min-height: 90vh;
}

#footerTest {
  position: absolute;
  width: 100%;
  bottom: 0;
  /* min-height: 80vh; */
  /* z-index: -1; */
}

.Typist {
  /* margin-top: 200px; */
  /* font-family: 'Shrikhand', cursive; */
  font-family: "Courier New", monospace;
  /* position: absolute; Position the background text */
  /* top: 25px; At the bottom. Use top:0 to append it to the top */
  /* background: rgb(0, 0, 0); Fallback color */
  /* background: rgba(0, 0, 0, 0.3);  Black background with 0.5 opacity */
  color: black; /* Grey text */
  /* Full width */
  /* padding: 20px;  Some padding */
  font-size: 36px;
  text-shadow: #f0e2c5 0px 0px 6px;
  /* width: 100%; */
  /* z-index: -1; */
}

/* .carousel .carousel-slider {
  height: 95vh;
} */

html {
  /* background: linear-gradient(#f0e2c5,white,#f0e2c5); */
  background: #f0e2c5;
  height: 100%;
  z-index: -2;
}

.home {
  z-index: 1;
}

@media only screen and (max-width: 550px) {
  .main {
    margin-top: 135px;
    overflow: scroll;
  }
}

@media only screen and (min-width: 750px) and (max-width: 850px) {
  .main {
    margin-top: 75px;
    overflow: scroll;
  }
}
