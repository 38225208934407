.modalBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}

.PopUp {
  position: relative;
  z-index: 8;
  left: 25%;
  /* top: 30%; */
  width: 50%;
  border-radius: 1em;
  border: 6px solid #f0e2c5;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #011627;
}

.sent {
  display: none;
}

.pu-content-container {
  font-family: "Courier New", monospace;
  padding: 20px 30px;
}

#contactX {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 28px;
  color: #f0e2c5;
}

#contactX:hover {
  cursor: pointer;
  color: #f0e2c5;
}

#name,
#email {
  width: 100%;
  padding: 0.6em;
  margin-top: 5px;
  box-sizing: border-box;
  font-family: "Courier New", monospace;
  outline: none !important;
}

.form-item {
  margin-bottom: 20px;
}

#message {
  max-width: 100%;
  width: 100%;
  min-height: 100px;
  padding: 0.4em;
  margin-top: 5px;
  box-sizing: border-box;
  font-family: "Courier New", monospace;
  outline: none !important;
}

#contactSubmit {
  width: 100%;
  padding: 8px;
  background-color: #f0e2c5;
  border: none;
  border: 4px solid #f0e2c5;
  box-sizing: border-box;
  font-family: "Courier New", monospace;
  font-size: 18px;
  color: white;
  font-weight: 800;
}

#contactSubmit:hover {
  width: 100%;
  padding: 8px;
  background-color: white;
  border: 4px solid #f0e2c5;
  color: #f0e2c5;
  cursor: pointer;
}

form {
  width: 100%;
  font-family: "Courier New", monospace;
}

.PopUp h1 {
  margin-top: 0;
  /* font-family: 'Shrikhand', cursive; */
  font-family: "Courier New", monospace;
  color: #f0e2c5;
}

.error {
  color: #db2269;
  font-size: 0.825em;
  display: relative;
}

.errorBorder {
  border: 1px solid #db2269;
}

.normalBorder {
  border: 1px solid black;
}

.pu-content-container h1 {
  margin-top: 0;
  /* font-family: 'Shrikhand', cursive; */
  font-family: "Courier New", monospace;
  color: #f0e2c5;
}

.box {
  position: relative;
  z-index: 8;
  left: 25%;
  /* top: 30%; */
  width: 50%;
  border-radius: 1em;
  border: 6px solid #f0e2c5;
  background-color: white;
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #011627;
}
