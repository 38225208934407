#tabs {
  margin-top: -30px;
}

#tabsList {
  display: flex;
  list-style-type: none;
  border: none;
  padding-left: 0;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.active {
  background-color: #f0e2c5;
  border: none;
}

.inactive {
  border: none;
  background-color: white;
  transition: background-color 0.4s;
}

.inactive:hover {
  background-color: #f0e2c5;
}

.tab-item {
  display: inline-block;
  padding: 15px;
}

.tab-button {
  font-family: "Courier New", monospace;
  font-size: 16px;
  padding: 15px;
  border-radius: 1em;
  color: black;
}

.grid-container {
  align-items: center;
  justify-content: center;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0;
  margin-left: 0;
}
.grid-item {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 1em;
  box-sizing: border-box;
  /* border: 2px solid #f0e2c5; */
  margin: 15px;
  padding: 10px 20px;
  font-size: 18px;
  /* text-align: center; */
  /* max-width: 33%; */
  /* min-width: 20%; */
  width: 290px;
  font-weight: 400;
  color: black;
  font-family: "Courier New", monospace;
  /* box-shadow: 0  5px 10px rgba(154,160,185,0.05), 0 15px 40px rgba(166,173,201,0.2); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}
